/* eslint-disable no-unused-expressions */
import * as Klaro from 'klaro/dist/klaro-no-css';

const klaroConfig = {

  testing: false,
  elementID: 'klaro',
  storageMethod: 'cookie',
  storageName: 'klaro',
  htmlTexts: false,
  cookieDomain: '.messekits.de',
  cookieExpiresAfterDays: 30,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  hideLearnMore: false,

  translations: {
    de: {
      privacyPolicyUrl: '/datenschutz',
      consentNotice: {
        description: "Hallo! Könnten wir bitte zusätzliche Dienste für {purposes} aktivieren? Sie können Ihre Zustimmung später jederzeit ändern oder zurückziehen. Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}.",
        learnMore: "Einstellungen",
      },
      decline: 'Ablehnen',
      ok: 'Akzeptieren',
      consentModal: {
        title: "Einstellungen zu Diensten und Cookies",
        description:
        'Wir verwenden Cookies und Drittanbieterdienste, um Ihnen ein möglichst optimales und auf Ihre Bedürfnisse zugeschnittenes ​Erlebnis unseres Onlineangebots zu bieten. '
        + 'Dazu zählen Cookies, die für den reibungslosen und sicheren Betrieb des Onlineangebots notwendig sind, sowie solche die zu statistischen Zwecken verwendet werden um das Onlineangebot für Sie zu optimieren. '
        + 'Sie können selbst entscheiden, welche Dienste Sie erlauben möchten.',
      },
      purposes: {
        analytics: {
          title: 'Besucher-Statistiken',
          description: 'Diese Dienste verarbeiten personenbezogene Daten, um den von dieser Website angebotenen Service zu optimieren.',
        },
        functional: {
          title: 'Funktionalität',
          description: 'Dienste die für den Betrieb unserer Website zwingend erforderlich sind.',
        },
        embedding: {
          title: 'Eingebettete Inhalte',
          description: 'Eingebettete Inhalte wie z.B. Videos',
        },
      },
    },
  },

  services: [
    {

      name: 'analytics',
      purposes: ['analytics'],
      required: false,
      optOut: false,
      onlyOnce: true,
      translations: {
        zz: {
          title: 'Google Analytics',
        },
        de: {
          title: "Google Analytics",
          description: "Google Analytics ist ein Tool, mit dem wir messen können, wie Benutzer mit Website-Inhalten interagieren. Mit diesen Informationen optimieren wir unseren Webauftritt für Sie. Verarbeitung Ihrer Nutzerdaten durch Google.",
          cookieDetailsUrl: "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
          privacyPolicyUrl: "https://policies.google.com/privacy?hl={lang}",
        },
        en: {
          title: "Google Analytics",
          description: "Google Analytics is a tool with which we can measure how users interact with website content. With this information we optimize our website for you. Processing of your user data by Google.",
          cookieDetailsUrl: "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
          privacyPolicyUrl: "https://policies.google.com/privacy?hl={lang}",
        },
        fr: {
          title: "Google Analytics",
          description: "Google Analytics est un outil avec lequel nous pouvons mesurer la façon dont les utilisateurs interagissent avec le contenu du site Web. Avec ces informations, nous optimisons notre site Web pour vous. Traitement de vos données utilisateur par Google.",
          cookieDetailsUrl: "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
          privacyPolicyUrl: "https://policies.google.com/privacy?hl={lang}",
        },
      },
      cookies: [
        {
          pattern: "^_ga$",
          expiresAfter: "2 years",
          path: "/",
          _id: "_8tkk7rhnc",
        },
        {
          pattern: "^_gid$",
          expiresAfter: "24 hours",
          path: "",
          _id: "_50tc32o8v",
        },
        {
          pattern: "^_gat$",
          expiresAfter: "1 minute",
          path: "",
          _id: "_ma213a9s2",
        },
        {
          pattern: "^AMP_TOKEN$",
          expiresAfter: "1 year",
          path: "",
          _id: "_y5vh053f3",
        },
        {
          pattern: "^_gac_",
          expiresAfter: "90 days",
          path: "",
          _id: "_1u6lf1t6k",
        },
        {
          pattern: "^__utmz$",
          expiresAfter: "6 months",
          path: "",
          _id: "_zjcc95k83",
        },
        {
          pattern: "^__utma$",
          expiresAfter: "never",
          path: "",
          _id: "_dkeo8e86l",
        },
        {
          pattern: "^__utmb$",
          expiresAfter: "30 minutes",
          path: "",
          _id: "_yoewq47mg",
        },
        {
          pattern: "^__utmv$",
          expiresAfter: "never",
          path: "",
          _id: "_7uczuzukw",
        },
        {
          pattern: "^__utmt$",
          expiresAfter: "10 minutes",
          path: "",
          _id: "_jtab3aqih",
        },
        {
          pattern: "^__Secure$",
          expiresAfter: "",
          path: ".google.com",
          _id: "_lil7grc74",
        },
      ],
      localStorage: [],
      sessionStorage: [],
      elements: null,
      requests: [
        {
          url: "https://www\\.google-analytics\\.com",
        },
      ],
    },
    {
      name: 'vimeo',
      purposes: ['embedding'],
      default: false,
      required: false,
      optOut: false,
      contextualConsentOnly: false,
      onlyOnce: false,
      translations: {
        zz: {
          title: 'Vimeo',
        },
        de: {
          title: 'Vimeo',
          description: 'Einbettung von Vimeo Videos',
        },
        en: {
          title: 'Vimeo',
          description: 'Embedding of Vimeo Videos',
        },
        fr: {
          title: 'Vimeo',
          description: 'Pari de vidéos Vimeo',
        },
      },
      cookies: [

      ],
    },
    {
      name: 'youtube',
      purposes: ['embedding'],
      default: false,
      required: false,
      optOut: false,
      contextualConsentOnly: false,
      onlyOnce: false,
      translations: {
        zz: {
          title: 'YouTube',
        },
        de: {
          title: 'YouTube',
          description: 'Einbettung von YouTube Videos',
        },
        en: {
          title: 'YouTube',
          description: 'Embedding of YouTube Videos',
        },
        fr: {
          title: 'YouTube',
          description: 'Pari de vidéos YouTube',
        },
      },
      cookies: [

      ],
    },
    {
      name: 'klaro',
      purposes: ['functional'],
      default: true,
      required: true,
      optOut: false,
      contextualConsentOnly: false,
      onlyOnce: false,
      translations: {
        zz: {
          title: 'Consent-Einstellungen',
        },
        de: {
          title: 'Consent-Einstellungen',
          description: 'Speicherung der in diesem Consent-Banner getroffenen Einstellungen.',
        },
        en: {
          title: 'Consent settings',
          description: 'Storage of the settings made in this consent banner.',
        },
        fr: {
          title: 'Consent settings',
          description: 'Stockage des paramètres réalisés dans cette bannière de consentement.',
        },
      },
      cookies: [

      ],
    },
  ],
};

// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;
// we set up Klaro with the config
Klaro.setup(klaroConfig);

document.addEventListener('DOMContentLoaded', () => {
  const cookieConfig = document.querySelector('a.show-klaro');

  if (cookieConfig) {
    cookieConfig.addEventListener('click', (e) => {
      e.preventDefault;
      Klaro.show();
    });
  }
});
